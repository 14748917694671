/*
SEO History Navigation
 */
var body = $('body');

(function (window, undefined) {

	// Bind to StateChange Event
	History.Adapter.bind(window, 'statechange', function () { // Note: We are using statechange instead of popstate
		var State = History.getState(); // Note: We are using History.getState() instead of event.state
		// load another apparel category
		var urlEl = State.data.ajaxUrl;
		var myTh = setTimeout(function () {
			if (urlEl) {
				var index = $('.nav--related a[href*="' + urlEl + '"]').parent().attr('data-index');

				$('.paginer').removeClassStartingWith('paginer--index-');
				$('.paginer').addClass('paginer--index-' + index);

			}
			clearTimeout(myTh);
		}, 400);

	});

})(window);

$(function () {

	var ajaxLoad = $('#ajaxLoad');

	if (ajaxLoad.attr('data-href') !== '' && ajaxLoad.attr('data-href') !== undefined) {
		ajaxLoad.load(ajaxLoad.attr('data-href') + " #ajaxLoad > *", function () {

			var ele = $('.nav--related a[href*="' + location.pathname.split('/').reverse()[0] + '"]');

			if (!ele.parent().hasClass('active')) {
				if (location.pathname.split('/').reverse()[0]) {

					//change tab active class
					ele.parent().parent().find('li').removeClass('active');
					ele.parent().addClass('active');


					//change slide active class
					var ind = ele.parent().attr('data-index');
					$('.paginer').removeClassStartingWith('paginer--index-');
					$('.paginer').addClass('paginer--index-' + ind);

					//select box sync
					body.find(".selectBox option").removeAttr('selected');
					body.find(".selectBox option").each(function () {
						if ($(this).attr('data-index') === ind) {
							$(this).attr("selected", "selected");
							return false;
						}
					});

				}
			}
		});
	}

	//tab click
	body.on("click", ".nav--related a", function (e) {
		e.preventDefault();
		var _this = $(this);
		var myUrl = _this.attr('href');
		var myTitle = _this.attr('title');
		var index = _this.parent().attr('data-index');


		//change tab active class
		_this.parent().parent().find('li').removeClass('active');
		_this.parent().addClass('active');

		//$('.paginer').removeClassStartingWith('paginer--index-');
		$('.paginer').addClass('paginer--index-' + index);

		//sync select
		body.find(".selectBox option:selected").removeAttr('selected');
		body.find(".selectBox option").each(function () {
			if ($(this).attr('data-index') === index) {
				$(this).attr("selected", "selected");
				return false;
			}
		});

		History.pushState({ ajaxUrl: myUrl, page: "downloads" }, myTitle, myUrl);
	});

	//select box
	body.on('change', '.selectBox', function (e) {
		var optionSelected = $("option:selected", this);
		var myUrl = optionSelected.attr('data-href');
		var myTitle = this.value;
		var index = optionSelected.attr('data-index');

		//reset seleted options
		body.find(".selectBox option:selected").removeAttr('selected');
		$(this).attr('selected', 'selected');

		$('.paginer').removeClassStartingWith('paginer--index-');
		$('.paginer').addClass('paginer--index-' + index);

		//sync tab
		$('.nav--related li').removeClass('active');
		$(".nav--related").find("li[data-index='" + index + "']").addClass('active');


		History.pushState({ ajaxUrl: myUrl, page: "downloads" }, myTitle, myUrl);

	});

});

$.fn.removeClassStartingWith = function (filter) {
	$(this).removeClass(function (index, className) {
		return (className.match(new RegExp("\\S*" + filter + "\\S*", 'g')) || []).join(' ')
	});
	return this;
};
